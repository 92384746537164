import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout
    pageHeading="Uh-oh!"
    subHeading="We can't seem to find the page you're looking for."
  >
    <SEO title="Page not found | SpotlightUX" body="page-not-found" />
        <p>Maybe you'll find what you're looking for on the <Link to="/">homepage</Link>, or perhaps you're looking for an <Link to="/articles/">article</Link>.</p>
  </Layout>
)

export default NotFoundPage
